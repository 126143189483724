import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ProjectHeroLayout from "./ProjectHeroLayout";

const backgroundImage =
  "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/palms/img/waikikipalms.jpg";

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#7fc7d9",
    backgroundPosition: "center",
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProjectHero(props) {
  const { classes } = props;

  return (
    <ProjectHeroLayout
      backgroundClassName={classes.background}
    ></ProjectHeroLayout>
  );
}

ProjectHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectHero);
