import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const styles = (theme) => ({
  root: {
    marginBottom: 0,
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: "100%",
  },
  card: {
    backgroundColor: theme.palette.secondary.medium,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
});

function AboutUs(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container id="about-us-anchor" className={classes.container}>
        <Typography
          variant="h4"
          marked="center"
          align="center"
          className={classes.title}
          component="h2"
        >
          {"ABOUT ELITE CONSTRUCTION SERVICES"}
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">
              Elite Construction Services is Hawaii’s most trusted window, door
              and railing installation contractor.
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              Working alongside architects, engineers and consultants, who
              specialize in window, door and railing replacement and
              installation, allows us to remain at the forefront of technology
              by utilizing state of the art products, materials and techniques
              available for all types of window, door and railing installation
              projects.
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              Elite Construction Services offers an array of anodized, Kynar and
              powder coated aluminum railing systems for hotels, condominiums
              and commercial buildings in new or existing building
              installations. Anodized aluminum is recommended in coastal areas,
              as anodizing is the most resistant to the harsh salty and
              corrosive environments. Anodizing is a process for finishing
              aluminum alloys that employs electrolytic oxidation of the
              aluminum surface to produce a protective oxide coating.
            </Typography>{" "}
            <br />
            <Typography variant="h5">
              Our window, door and railing systems meet all International
              Building Codes (IBC), as well as City & County code requirements
              for the state of Hawaii.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </section>
  );
}

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUs);
