import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";

function Copyright() {
  return (
    <>
      {"© Copyright "}
      {new Date().getFullYear()}
      {" - All Rights Reserved, "}
      <Link color="inherit" href="https://www.ecs-hi.com/">
        ELITE Construction Services
      </Link>{" "}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100px",
    backgroundColor: theme.palette.primary.darkest,
    flexGrow: 1,
    margin: 0,
  },
  container: {
    display: "flex",
  },
  centered: {
    textAlign: "center",
    margin: "auto",
    padding: theme.spacing(2),
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightLight,
  },
  iconStyle: {
    width: 48,
    height: 48,
    alignItems: "center",
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container id="app-footer-anchor" className={classes.container}>
        <Grid
          style={{ justifyContent: "center", display: "flex" }}
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Box>
              <div className={classes.centered}>
                <Copyright />
              </div>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              component="img"
              className={classes.iconStyle}
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/pats/img/organization_1.jpg"
              alt="org1"
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              component="img"
              className={classes.iconStyle}
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/pats/img/organization_2.jpg"
              alt="org2"
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              component="img"
              className={classes.iconStyle}
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/pats/img/organization_3.jpg"
              alt="org3"
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              component="img"
              className={classes.iconStyle}
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/pats/img/organization_4.jpg"
              alt="org4"
            />
          </Grid>

          <Grid item xs={2}>
            <Box
              component="img"
              className={classes.iconStyle}
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/pats/img/organization_5.jpg"
              alt="org5"
            />
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
