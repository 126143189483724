export const listPalmsNotices = /* GraphQL */ `
  query ListPalmsNotices {
    listNotices(
      filter: { clientId: { eq: "9e66fc3e-32e0-4370-a60e-47a5ced612e5" } }
    ) {
      nextToken
      items {
        groupId
        pdfSource
        title
        id
        imgSource
      }
    }
  }
`;
