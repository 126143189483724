import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "../components/Typography";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    marginBottom: 0,
    display: "flex",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.white,
  },
});

const ImageBackdrop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#000",
  opacity: 0.5,
  transition: theme.transitions.create("opacity"),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  display: "block",
  padding: 0,
  borderRadius: 0,
  height: "40vh",
  [theme.breakpoints.down("md")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover": {
    zIndex: 1,
  },
  "&:hover .imageBackdrop": {
    opacity: 0.15,
  },
  "&:hover .imageMarked": {
    opacity: 0,
  },
  "&:hover .imageTitle": {
    border: "4px solid currentColor",
  },
  "& .imageTitle": {
    position: "relative",
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  "& .imageMarked": {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const mobilizationPhotos = [
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Mobilization+Photos/IMG_3227.jpg",
    id: uuidv4(),
    width: "33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Mobilization+Photos/IMG_3296.JPG",
    id: uuidv4(),
    width: "33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Mobilization+Photos/IMG_6282.JPG",
    id: uuidv4(),
    width: "33%",
  },
];

const constructionPhase1Photos = [
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_0163.HEIC.heif",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_0107.HEIC.heif",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_0132.HEIC.heif",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_0126.HEIC.heif",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_0101.HEIC.heif",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_3225.jpg",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/IMG_3226.jpg",
    id: uuidv4(),
    width: "8.33%",
  },

  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/attachment_1.jpg",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/attachment_2.jpg",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/attachment_3.jpg",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/attachment_4.HEIC",
    id: uuidv4(),
    width: "8.33%",
  },
  {
    url: "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/Construction+Phase+1/attachment_5.jpg",
    id: uuidv4(),
    width: "8.33%",
  },
];

function Photos(props) {
  const { classes } = props;

  const [open, setOpen] = React.useState(false);
  const [currentPhoto, setCurrentPhoto] = React.useState();

  const handleBoxClick = (event, url) => {
    setCurrentPhoto(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <section className={classes.root}>
      <Container className={classes.container} id="photos-anchor">
        <Typography
          className={classes.title}
          variant="h4"
          marked="center"
          align="center"
          component="h2"
        >
          RECENT PROJECT PHOTOS
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component="h4" variant="h5" color="inherit">
              {"Mobilization"}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {mobilizationPhotos.map((photo) => (
                <ImageIconButton
                  onClick={(e) => handleBoxClick(e, photo.url)}
                  key={photo.id}
                  style={{
                    width: photo.width,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundSize: "cover",
                      backgroundPosition: "center 40%",
                      backgroundImage: `url(${photo.url})`,
                    }}
                  />
                  <ImageBackdrop className="imageBackdrop" />
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "common.white",
                    }}
                  ></Box>
                </ImageIconButton>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component="h4" variant="h5" color="inherit">
              {" Construction Phase 1"}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {constructionPhase1Photos.map((photo) => (
                <ImageIconButton
                  onClick={(e) => handleBoxClick(e, photo.url)}
                  key={photo.id}
                  style={{
                    width: photo.width,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundSize: "cover",
                      backgroundPosition: "center 40%",
                      backgroundImage: `url(${photo.url})`,
                    }}
                  />
                  <ImageBackdrop className="imageBackdrop" />
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "common.white",
                    }}
                  ></Box>
                </ImageIconButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={open} maxWidth="false" onClose={handleClose}>
        <MuiDialogTitle>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent sx={{ overflow: "hidden" }}>
          <Box
            style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
            component="img"
            alt="building-photo"
            src={currentPhoto}
          />
        </MuiDialogContent>
      </Dialog>
    </section>
  );
}

Photos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Photos);
