import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "../components/Typography";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import WarningIcon from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import { Link } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { listPalmsNotices } from "../../graphql/custom-queries";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { generateClient } from "aws-amplify/api";
import ParkingNotices from "./ParkingNotices";

const client = generateClient();

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  notices: {
    marginTop: theme.spacing(8),
    flexGrow: 1,
  },
  parkingNotices: {
    marginTop: theme.spacing(8),
    color: theme.palette.error.main,
    textAlign: "center",
    border: "2px solid  " + theme.palette.error.main,
    padding: theme.spacing(2),
  },
  alertPaper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: 5,
    textAlign: "center",
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.medium,
    "& .MuiSvgIcon-root": {
      marginBottom: theme.spacing(-0.3),
    },
  },
  paper: {
    textAlign: "center",
    backgroundColor: theme.palette.secondary.light,
    minHeight: 582,
  },
  alertCardPaper: {
    textAlign: "center",
    backgroundColor: theme.palette.secondary.light,
  },
  alertNoticeImage: {
    maxWidth: "85%",
    paddingBottom: theme.spacing(2),
  },
  noticeImage: {
    maxWidth: "90%",
    paddingBottom: theme.spacing(2),
  },
  openLink: {
    padding: theme.spacing(4),
    "& .MuiSvgIcon-root": {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(-0.7),
    },
  },
  accordion: {
    marginTop: theme.spacing(2),
  },
});

const palmsNotices = [];

function Documents(props) {
  const { classes } = props;
  const [palmsNotices, setPalmsNotices] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const noticesData = await client.graphql({
        query: listPalmsNotices,
      });

      const noticesList = noticesData.data.listNotices.items;
      setPalmsNotices(noticesList);
    } catch (error) {
      setOpenAlert(true);
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Container
      id="documents-anchor"
      className={classes.root}
      component="section"
    >
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          <Typography variant="subtitle1">
            {"Could not load notices, please try again later!"}
          </Typography>
        </Alert>
      </Snackbar>
      <ParkingNotices />
      <Paper className={classes.alertPaper} elevation={3}>
        <Typography variant="h5" component="h1">
          <WarningIcon /> {"Important:"}
        </Typography>
        <Typography variant="subtitle1">
          {"This is where important announcements will be published."}
        </Typography>
      </Paper>
      <Typography variant="h4" marked="center" align="center" component="h2">
        Documents
      </Typography>
      <div className={classes.notices}>
        <Grid container spacing={2}>
          {palmsNotices
            .filter((element) => element.groupId === 1)
            .map((notice) => (
              <Grid key={notice.id} item xs={12} sm={6} md={4}>
                <Paper className={classes.paper} elevation={3}>
                  <Link href={notice.pdfSource} target="_blank">
                    <Typography
                      className={classes.openLink}
                      variant="subtitle1"
                    >
                      {notice.title}
                      <PictureAsPdfIcon />
                    </Typography>
                  </Link>
                  <Box
                    component="img"
                    className={classes.noticeImage}
                    alt="start-notice"
                    src={notice.imgSource}
                  />
                </Paper>
              </Grid>
            ))}
        </Grid>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} variant="subtitle1">
              Previous Documents
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {palmsNotices
                .filter((element) => element.groupId === 2)
                .map((notice) => (
                  <Grid key={notice.id} item xs={12} sm={6} md={4}>
                    <Paper className={classes.paper} elevation={3}>
                      <Link href={notice.pdfSource} target="_blank">
                        <Typography
                          className={classes.openLink}
                          variant="subtitle1"
                        >
                          {notice.title}
                          <PictureAsPdfIcon />
                        </Typography>
                      </Link>
                      <Box
                        component="img"
                        className={classes.noticeImage}
                        alt="start-notice"
                        src={notice.imgSource}
                      />
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Documents);
