/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getParkingStallClosures = /* GraphQL */ `
  query GetParkingStallClosures($id: ID!) {
    getParkingStallClosures(id: $id) {
      id
      title
      dateRange
      closureDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listParkingStallClosures = /* GraphQL */ `
  query ListParkingStallClosures(
    $filter: ModelParkingStallClosuresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParkingStallClosures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        dateRange
        closureDetails
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      clientId
      title
      pdfSource
      imgSource
      groupId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        title
        pdfSource
        imgSource
        groupId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
