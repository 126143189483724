import React from "react";
import ReactDOM from "react-dom";
import { StrictMode } from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";
import { Authenticator } from "@aws-amplify/ui-react";

Amplify.configure(amplifyconfig);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
